<template>
  <v-dialog
    persistent
    v-model="dialog"
    overlay-color="black"
    overlay-opacity="0.5"
  >
    <div class="tw-flex tw-w-full tw-flex-col">
      <div
        style=" width: 30px; height: 30px; border-radius: 50%; z-index: 1"
        class="tw-flex tw-justify-center  tw-items-center tw-self-end  tw-cursor-pointer"
        @click="$emit('close')"
      >
        <vue-feather type="x" style="color: white; " />
      </div>
      <div v-if="section === 'main'"
        class=" tw-flex card tw-p-9 tw-justify-center tw-items-center tw-flex-col"
        style="min-height: 384px"
      >
        <icons :name="icon" :color="color" />
        <p class="title tw-py-5 tw-mb-0" :style="{color:color}">
          {{ title }}&nbsp;
        </p>
        <label
          class="desc "
          v-if="description">{{ description }}</label>

        <div class="tw-flex tw-w-full tw-justify-around tw-items-center tw-mt-20">
          <v-btn
              text
              class="first-btn"
              @click="$emit('close')"
              :style="{
              color: color,
              border: '1px solid' + color,}">
            Close
          </v-btn>
          <v-btn
              text
              class="second-btn"
              @click="handleVerifyTransportCompany"
              :style="{ background: color, color: 'white' }"
              :loading="loading">
            {{this.title}}
          </v-btn>
        </div>
      </div>
      <div v-if="section === 'delete-post'"
           class=" tw-flex card tw-p-9 tw-justify-center tw-items-center tw-flex-col"
           style="min-height: 384px"
      >
        <icons :name="icon" :color="color" />
        <p class="title tw-py-5 tw-mb-0" :style="{color:color}">
          {{ title }}&nbsp;
        </p>
        <label
            class="desc "
            v-if="description">{{ description }}</label>

        <div class="tw-flex tw-w-full tw-justify-around tw-items-center tw-mt-20">
          <v-btn
              text
              class="first-btn"
              @click="$emit('close')"
              :style="{
              color: color,
              border: '1px solid' + color,}">
            Close
          </v-btn>
          <v-btn
              text
              class="second-btn"
              @click="$emit('deletePost')"
              :style="{ background: color, color: 'white' }"
              :loading="loading">
            Delete
          </v-btn>
        </div>
      </div>
      <div v-if="section === 'delete-booking'"
           class=" tw-flex card tw-p-9 tw-justify-center tw-items-center tw-flex-col"
           style="min-height: 384px"
      >
        <icons :name="icon" :color="color" />
        <p class="title tw-py-5 tw-mb-0" :style="{color:color}">
          {{ title }}&nbsp;
        </p>
        <label
            class="desc "
            v-if="description">{{ description }}</label>

        <div class="tw-flex tw-w-full tw-justify-around tw-items-center tw-mt-20">
          <v-btn
              text
              class="first-btn"
              @click="$emit('close')"
              :style="{
              color: color,
              border: '1px solid' + color,}">
            Close
          </v-btn>
          <v-btn
              text
              class="second-btn"
              @click="$emit('deleteBooking')"
              :style="{ background: color, color: 'white' }"
              :loading="loading">
            Delete
          </v-btn>
        </div>
      </div>
      <div v-if="section === 'pos'"
           class=" tw-flex card tw-p-5 tw-justify-center tw-items-center tw-flex-col"
           style="min-height: 384px">
        <icons :name="icon" :color="color" height="3rem" width="2.9rem" />
        <p class="title tw-py-5 tw-mb-0" :style="{color:color}">
          {{ title }}&nbsp;
        </p>
        <div class="tw-flex tw-w-full tw-py-3">
          <v-combobox :items="transportCompanies"  v-model="posRequest.selectedTransportCompany" item-text="companyName"
                      solo hide-details placeholder="Select Transport Company"
                      append-icon="mdi-chevron-down" @change="getAllTerminalsOfTransportCompany">

          </v-combobox>
        </div>
        <div class="tw-flex tw-w-full tw-py-3">
          <v-combobox  solo hide-details placeholder="Select Terminal"
                       :items="transportCompanyTerminals" v-model="posRequest.selectedTerminal"
                       append-icon="mdi-chevron-down" item-text="terminalName">

          </v-combobox>
        </div>
        <div class="tw-flex tw-w-full tw-py-3">
          <v-text-field  solo hide-details v-model="posRequest.numberOfPos" placeholder="Number of POS" type="Number">

          </v-text-field>
        </div>
        <div class="tw-flex tw-w-full tw-py-3">
          <v-text-field  solo hide-details v-model="posRequest.unitPrice" placeholder="Unit Price" type="Number">

          </v-text-field>
        </div>
        <div class="tw-flex tw-w-full tw-justify-around tw-items-center tw-mt-5">
          <v-btn
              text
              class="first-btn"
              @click="$emit('close')"
              :style="{
              color: color,
              border: '1px solid' + color,}">
            Cancel
          </v-btn>
          <v-btn
              text
              class="second-btn"
              @click="addPosRequest"
              :style="{ background: color, color: 'white' }"
              :loading="posLoading">
            Submit Request
          </v-btn>
        </div>
      </div>

    </div>
  </v-dialog>
</template>

<script>
import check from "../../assets/check_one.svg";
import Icons from "@/components/reuseables/Icons";
import {AddPosRequest, getAllTransportCompanies, getTransportCompanyTerminals} from "@/services/api/APIService";
export default {
  name: "Modal",
  components: { Icons },
  props: {
    dialog: Boolean,
    icon: {
      type: String,
      default: "check",
    },
    title: {
      type: String,
      default: "Title",
    },
    description: {
      type: String,
      default: "",
    },

    color: {
      type: [String],
      default: "#004AAD",
    },
    section:{
      type: String,
      default : "main"
    },
    loading:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      check,
      posRequest:{
        selectedTerminal : null,
        selectedTransportCompany:null,
      },
      transportCompanies :[],
      transportCompanyTerminals:[],
     posLoading: false
    };
  },
  methods:{
    handleVerifyTransportCompany(){
       this.$emit('verifyTransportCompany')
    },
    async getAllTransportCompanies(){
      await getAllTransportCompanies().then(res => {
        this.transportCompanies = res.data.filter(tc => tc.transportCompanyVerified === true)
      })
    },
    async getAllTerminalsOfTransportCompany(){
      this.posRequest.selectedTerminal = null
      if (Object.keys(this.posRequest.selectedTransportCompany).length) {
        await getTransportCompanyTerminals(this.posRequest.selectedTransportCompany.id).then(res => {
          this.transportCompanyTerminals = res.data
        })
      }
    },
    addPosRequest(){
      this.posLoading = true
      if (this.validRequest()) {
        let numberOfPosRequested = []
        for (let i = 1; i <= this.posRequest.numberOfPos; i++) {
          let pos = {}
          pos.unitPrice = this.posRequest.unitPrice
          numberOfPosRequested.push(pos)
        }
        this.posRequest.requestedPos = numberOfPosRequested
        this.posRequest.transportCompanyId = this.posRequest.selectedTransportCompany.id
        this.posRequest.terminalId = this.posRequest.selectedTerminal.id
        AddPosRequest(this.posRequest).then(() =>{
          this.posLoading = false
          this.$displaySnackbar({
            message: "Pos Request submitted successfully!",
            success: true
          })
          this.$emit('close')
        }).catch(err => {
              this.$displaySnackbar({
                message: err.response.data,
                success: false
              })
          this.posLoading = false
            }).finally(() => this.posLoading = false)
      }
      else {
        this.posLoading = false
      }
    },
    validRequest(){
      let valid = true
      if (this.posRequest.selectedTransportCompany === null
          || this.posRequest.selectedTerminal === null || !this.posRequest.numberOfPos || !this.posRequest.unitPrice){
        this.$displaySnackbar({
          message: "All fields are required",
          success: false
        })
        valid = false
      }
      return valid
    }
  },
  async created() {
    await this.getAllTransportCompanies();
  }
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog {
  box-shadow: none !important;
  width: 80% !important;
  @media screen and (min-width: 768px) {
    width: 30% !important;
  }
}
.card {
  width: 97%;
  background-color: white;
  border-radius: 20px;

}
.title {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 36px !important;
  margin-bottom: 0 !important;
  line-height: 44px;
  text-align: center;
  color: #004aad;
}

.cancel-title {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px !important;
  margin-bottom: 0 !important;
  line-height: 24px;
  text-align: left;
}
.desc {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #333333;
}

.bottomtext {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #004aad;
  text-decoration: none;
}

.second-btn{
  text-transform: none;
}
.first-btn{
  text-transform: none;
}
</style>
